.content {
	font-size: 18px !important;
}
.bold {
	font-weight: bold !important;
}
h1 {
	font-size: 30px !important;
}
h2 {
	font-size: 26px !important;
}
h3 {
	font-size: 22px !important;
}
.PXE-hr {
	content: ' ';
	display: block;
	width: 100%;
	height: 3.9em;
	margin-bottom: 5px;
	margin-top: 5px;
	background: linear-gradient(to right, #465d79, #7ba4da, #465d79) center / 100%
			2px no-repeat,
		linear-gradient(to right, #465d79, transparent 25%, transparent 75%, #465d79)
			center / 100% 4px no-repeat;
	clear: both;
}
.color-1e70bf {
	color: #1e70bf !important;
}
.list a {
	color: #05305a !important;
}
.list a:hover {
	text-decoration: underline !important;
	color: #465d79 !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mb-25 {
	margin-bottom: 25px !important;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-5 {
	margin-bottom: 5px;
}

.mt-50 {
	margin-top: 50px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-25 {
	margin-top: 25px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-5 {
	margin-top: 5px;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.ml-2em {
	margin-left: 2em !important;
}
.mt-0 {
	margin-top: 0px !important;
}
.mt-15 {
	margin-top: 15px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.pt-48 {
	padding-top: 48px !important;
}
.pb-48 {
	padding-bottom: 48px !important;
}
.clear-both {
	clear: both;
}
.inlineImage {
	margin-left: 5px;
	margin-right: 5px;
	top: 5px;
}
.content {
	margin-top: 10px;
}

html {
	scroll-behavior: smooth;
}

ul li {
	margin: 8px !important;
}

.navbar ul li {
	margin: 0px !important;
}

.logo {
	margin-bottom: 10px !important;
}

[class*='list'] {
	padding-left: 0;
	list-style-type: none;
}

[class*='list'] li::before {
	float: none;
	margin-left: 0;
	display: inline;
	font-family: FontAwesome;
	color: #04305a;
	padding: 0 8px;
}

.list-space li {
	margin-bottom: 15px !important;
}

.list-right li::before {
	content: '\f105';
}

.list-check li::before {
	content: '\f00c';
}

.list-star li::before {
	content: '\f005';
}

.center {
	text-align: center;
	margin: 0 auto;
}

.text-center {
	text-align: center;
}

.border-1px {
	border: solid 1px #e8e8e8;
}

.float-left {
	float: left !important;
}

@media only screen and (max-width: 767px) {
	.text-center-767px {
		text-align: center;
		margin: 40px auto 0 !important;
	}
}

.thumbnail-newsletter img {
	min-width: 150px !important;
	height: 240px !important;
}

.overview-quicklinks.ui.list > .item {
	line-height: 1.4em !important;
}

.overview-bulletins.ui.list > .item {
	line-height: 1.4em !important;
}

/* Button classes */
.button-default {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	background-color:#D9EDF4 !important;
}
.button-default:hover, .button-default:active {
	background-color: #BDD4E0 !important;
}
.button-secondary {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	border: 1px solid #052C53 !important;
	background-color: transparent !important;
}
.button-secondary:hover, .button-secondary:active, .button-secondary:hover path, .button-secondary:active path {
	color: #FFFFFF !important;
	fill: #FFFFFF !important;
	background-color: #052C53 !important;
}
.button-tertiary {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	background-color:#FFFFFF !important;
}
.button-tertiary:hover, .button-tertiary:active, .button-tertiary:hover path, .button-tertiary:active path {
	color: #FFFFFF !important;
	fill: #FFFFFF !important;
	background-color: #052C53 !important;
}
.button-disabled {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color: #95989F !important;
	background-color: #EEEFF0 !important;
	cursor: default !important;
	pointer-events: none !important;
}
.button-default img, .button-secondary svg, .button-tertiary svg, .button-disabled img {
	width: 24px !important;
} /* may have to change depending on differently sized icons: default, small, large */
/* End button classes */