.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	color: #294761;
	font-weight: 700;
}

.multilevelMenu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.multilevelMenu ul li {
	display: block;
	position: relative;
	float: left;
}
.multilevelMenu li ul {
	display: none;
	z-index: 11;
}

.main-navigation li:last-child ul {
	width: 262px;
}

.multilevelMenu ul li a {
	display: block;
	padding: 1em;
	text-decoration: none;
	white-space: nowrap;
	color: #294761;
}

.multilevelMenu ul li a:hover {
	background: #294761;
	color: #fff;
}

.multilevelMenu li:hover > ul {
	display: block;
	position: absolute;
}

.multilevelMenu li:hover li {
	float: none;
}
.multilevelMenu ul ul ul {
	left: 100%;
	top: 0;
}
.multilevelMenu ul:before,
.multilevelMenu ul:after {
	content: ' '; /* 1 */
	display: table; /* 2 */
}

.multilevelMenu ul:after {
	clear: both;
}

.multilevelMenu li:hover a {
	background: #efefef;
}

.multilevelMenu li:hover li a:hover {
	background: #294761;
	color: #fff;
}

.multilevelMenu .main-navigation li ul li {
	border-top: 0;
}

.fa-bars {
	color: #294761;
}

.nav-links {
	padding: 25px !important;
}

.nav-links.active {
	color: #d07430 !important;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (min-width: 990px) {
	.showNav {
		display: none;
	}
}

@media screen and (max-width: 990px) {
	.hideNav {
		display: none;
	}

	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		justify-content: left;
		flex-direction: column;
		width: 50%;
		/* height: 80vh; */
		position: absolute;
		top: 115px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu a {
		padding: 2%;
		text-decoration: none;
		/* font-size: 25px; */
		color: #294761;
		display: block;
		transition: 0.3s;
		text-align: center;
	}

	.nav-menu.active {
		background: #efefef;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 11;
		width: 100%;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 65px;
		right: 20px;
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #132d40;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		background: #132d40;
		text-decoration: none;
		color: #fff;
		font-size: 1.5rem;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #132d40;
		transition: 250ms;
	}
}
