.staff-section:hover {
	background-color: #ebebeb;
}

@media screen and (max-width: 752px) {
	.center {
		text-align: center !important;
	}
}

.round-image {
	border-radius: 50%;
}

.staff-icon-size {
	object-fit: cover;
	width: 250px;
	height: 250px; 
}