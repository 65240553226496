.footer-background {
    background-color: #D9EDF4 !important;
}
.footer-content {
    padding-top: 40px !important; 
    padding-bottom: 40px !important; 
}
.footer-logo {
    width: 135px; 
}
.footer-contact {
    margin-top: 40px !important; 
    margin-bottom: 40px !important; 
}

@media screen and (max-width: 767px) {
    .footer-contact {
        margin-top: 15px !important; /* should be 30px but reduced because of default React Semantic UI padding */
    }
    .footer-sign-up-button {
        width: 100% !important;
    }
}