@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap);
.neutral-0 {
	color: #fff;
}

.neutral-100 {
	color: #e0e1e3;
}

.neutral-200 {
	color: #c4c6ca;
}

.neutral-300 {
	color: #a8abb0;
}

.neutral-400 {
	color: #8d9198;
}

.neutral-500 {
	color: #73777f;
}

.neutral-600 {
	color: #5b5e65;
}

.neutral-700 {
	color: #45474c;
}

.neutral-800 {
	color: #2e3034;
}

.neutral-900 {
	color: #1a1b1d;
}

.neutral-1000 {
	color: #000;
}

.pxe-blue-100 {
	color: #d1d7dd;
}

.pxe-blue-200 {
	color: #b6c1c9;
}

.pxe-blue-300 {
	color: #9fadb8;
}

.pxe-blue-400 {
	color: #8899a7;
}

.pxe-blue-500 {
	color: #718496;
}

.pxe-blue-600 {
	color: #5a7286;
}

.pxe-blue-700 {
	color: #445f75;
}

.pxe-blue-800 {
	color: #294761;
}

.pxe-blue-900 {
	color: #223a50;
}

.red-100 {
	color: #d1d7dd;
}

.red-200 {
	color: #b6c1c9;
}

.red-300 {
	color: #9fadb8;
}

.red-400 {
	color: #8899a7;
}

.red-500 {
	color: #718496;
}

.red-600 {
	color: #5a7286;
}

.red-700 {
	color: #445f75;
}

.red-800 {
	color: #294761;
}

.red-900 {
	color: #223a50;
}

.blue-100 {
	color: #d1d7dd;
}

.blue-200 {
	color: #b6c1c9;
}

.blue-300 {
	color: #9fadb8;
}

.blue-400 {
	color: #8899a7;
}

.blue-500 {
	color: #718496;
}

.blue-600 {
	color: #5a7286;
}

.blue-700 {
	color: #445f75;
}

.blue-800 {
	color: #294761;
}

.blue-900 {
	color: #223a50;
}

.green-100 {
	color: #d1d7dd;
}

.green-200 {
	color: #b6c1c9;
}

.green-300 {
	color: #9fadb8;
}

.green-400 {
	color: #8899a7;
}

.green-500 {
	color: #718496;
}

.green-600 {
	color: #5a7286;
}

.green-700 {
	color: #445f75;
}

.green-800 {
	color: #294761;
}

.green-900 {
	color: #223a50;
}

.bg-default {
	background-color: #fff;
}

.bg-subtle {
	background-color: #a8abb0;
}

.bg-brand {
	background-color: #d9edf4;
}

.bg-brand:hover {
	background-color: #bdd4e0;
}

.bg-knockout {
	background-color: #052c53;
}

.content-default {
	color: #1b1c1e;
}

.content-subtle {
	color: #616368;
}

.content-default:hover {
	color: #052c53;
}

.content-brand {
	color: #052c53;
}

.content-brand:hover {
	color: #4d708f;
}

.content-knockout {
	color: #a8abb0;
}

.content-knockout:hover {
	color: #d9edf4;
}

.content-disabled {
	color: #a8abb0;
}

.border-default {
	border-color: #052c53;
}

.border-subtle {
	border-color: #d0d1d4;
}

.border-xsubtle {
	border-color: #eeeff0;
}

.border-brand {
	border-color: #6788a2;
}

.border-knockout {
	border-color: #ffffff;
}

.border-knockout-subtle {
	border-color: #6b7b92;
}

.border-knockout-xsubtle {
	border-color: #33597b;
}

.border-disabled {
	border-color: #d0d1d4;
}

.body-large {
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	font-family: Manrope;
	color: #000;
}

.body-default {
	font-family: Manrope;
	color: #000;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.body-small {
	font-family: Manrope;
	color: #1B1C1E;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.body-small-link {
	font-family: Manrope;
	color: #1B1C1E;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

.body-small-link:hover {
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

.body-xsmall {
	font-family: Manrope;
	color: #000;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.body-xsmall-link {
	font-family: Manrope;
	color: #000;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

.body-xsmall-link:hover {
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
}

.label-default {
	font-family: Manrope;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	line-height: 28px;
}

.label-small {
	font-family: Manrope;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

.display-large {
	font-family: Manrope;
	color: #000;
	font-size: 48px;
	font-weight: 300;
	line-height: 56px;
	letter-spacing: -2px;
}

.headline-large {
	font-family: Manrope;
	font-size: 48px;
	font-weight: 700;
	line-height: 56px;
	letter-spacing: -2px;
	color: #000;
}

.title-default {
	font-family: Manrope;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: -1px;
	color: #000;
}

.button-default {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: #000;
}

.content {
	font-size: 18px !important;
}
.bold {
	font-weight: bold !important;
}
h1 {
	font-size: 30px !important;
}
h2 {
	font-size: 26px !important;
}
h3 {
	font-size: 22px !important;
}
.PXE-hr {
	content: ' ';
	display: block;
	width: 100%;
	height: 3.9em;
	margin-bottom: 5px;
	margin-top: 5px;
	background: linear-gradient(to right, #465d79, #7ba4da, #465d79) center / 100%
			2px no-repeat,
		linear-gradient(to right, #465d79, transparent 25%, transparent 75%, #465d79)
			center / 100% 4px no-repeat;
	clear: both;
}
.color-1e70bf {
	color: #1e70bf !important;
}
.list a {
	color: #05305a !important;
}
.list a:hover {
	text-decoration: underline !important;
	color: #465d79 !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mb-25 {
	margin-bottom: 25px !important;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-5 {
	margin-bottom: 5px;
}

.mt-50 {
	margin-top: 50px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-25 {
	margin-top: 25px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-5 {
	margin-top: 5px;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.ml-2em {
	margin-left: 2em !important;
}
.mt-0 {
	margin-top: 0px !important;
}
.mt-15 {
	margin-top: 15px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.pt-48 {
	padding-top: 48px !important;
}
.pb-48 {
	padding-bottom: 48px !important;
}
.clear-both {
	clear: both;
}
.inlineImage {
	margin-left: 5px;
	margin-right: 5px;
	top: 5px;
}
.content {
	margin-top: 10px;
}

html {
	scroll-behavior: smooth;
}

ul li {
	margin: 8px !important;
}

.navbar ul li {
	margin: 0px !important;
}

.logo {
	margin-bottom: 10px !important;
}

[class*='list'] {
	padding-left: 0;
	list-style-type: none;
}

[class*='list'] li::before {
	float: none;
	margin-left: 0;
	display: inline;
	font-family: FontAwesome;
	color: #04305a;
	padding: 0 8px;
}

.list-space li {
	margin-bottom: 15px !important;
}

.list-right li::before {
	content: '\f105';
}

.list-check li::before {
	content: '\f00c';
}

.list-star li::before {
	content: '\f005';
}

.center {
	text-align: center;
	margin: 0 auto;
}

.text-center {
	text-align: center;
}

.border-1px {
	border: solid 1px #e8e8e8;
}

.float-left {
	float: left !important;
}

@media only screen and (max-width: 767px) {
	.text-center-767px {
		text-align: center;
		margin: 40px auto 0 !important;
	}
}

.thumbnail-newsletter img {
	min-width: 150px !important;
	height: 240px !important;
}

.overview-quicklinks.ui.list > .item {
	line-height: 1.4em !important;
}

.overview-bulletins.ui.list > .item {
	line-height: 1.4em !important;
}

/* Button classes */
.button-default {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	grid-gap: 8px !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	background-color:#D9EDF4 !important;
}
.button-default:hover, .button-default:active {
	background-color: #BDD4E0 !important;
}
.button-secondary {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	grid-gap: 8px !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	border: 1px solid #052C53 !important;
	background-color: transparent !important;
}
.button-secondary:hover, .button-secondary:active, .button-secondary:hover path, .button-secondary:active path {
	color: #FFFFFF !important;
	fill: #FFFFFF !important;
	background-color: #052C53 !important;
}
.button-tertiary {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	grid-gap: 8px !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color:#052C53 !important;
	background-color:#FFFFFF !important;
}
.button-tertiary:hover, .button-tertiary:active, .button-tertiary:hover path, .button-tertiary:active path {
	color: #FFFFFF !important;
	fill: #FFFFFF !important;
	background-color: #052C53 !important;
}
.button-disabled {
	display: inline-flex !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	grid-gap: 8px !important;
	gap: 8px !important;
	font-family: Manrope !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 24px !important; /* 133.333% */
	border-radius: 0px !important;
	color: #95989F !important;
	background-color: #EEEFF0 !important;
	cursor: default !important;
	pointer-events: none !important;
}
.button-default img, .button-secondary svg, .button-tertiary svg, .button-disabled img {
	width: 24px !important;
} /* may have to change depending on differently sized icons: default, small, large */
/* End button classes */

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	color: #294761;
	font-weight: 700;
}

.multilevelMenu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.multilevelMenu ul li {
	display: block;
	position: relative;
	float: left;
}
.multilevelMenu li ul {
	display: none;
	z-index: 11;
}

.main-navigation li:last-child ul {
	width: 262px;
}

.multilevelMenu ul li a {
	display: block;
	padding: 1em;
	text-decoration: none;
	white-space: nowrap;
	color: #294761;
}

.multilevelMenu ul li a:hover {
	background: #294761;
	color: #fff;
}

.multilevelMenu li:hover > ul {
	display: block;
	position: absolute;
}

.multilevelMenu li:hover li {
	float: none;
}
.multilevelMenu ul ul ul {
	left: 100%;
	top: 0;
}
.multilevelMenu ul:before,
.multilevelMenu ul:after {
	content: ' '; /* 1 */
	display: table; /* 2 */
}

.multilevelMenu ul:after {
	clear: both;
}

.multilevelMenu li:hover a {
	background: #efefef;
}

.multilevelMenu li:hover li a:hover {
	background: #294761;
	color: #fff;
}

.multilevelMenu .main-navigation li ul li {
	border-top: 0;
}

.fa-bars {
	color: #294761;
}

.nav-links {
	padding: 25px !important;
}

.nav-links.active {
	color: #d07430 !important;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (min-width: 990px) {
	.showNav {
		display: none;
	}
}

@media screen and (max-width: 990px) {
	.hideNav {
		display: none;
	}

	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		justify-content: left;
		flex-direction: column;
		width: 50%;
		/* height: 80vh; */
		position: absolute;
		top: 115px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu a {
		padding: 2%;
		text-decoration: none;
		/* font-size: 25px; */
		color: #294761;
		display: block;
		transition: 0.3s;
		text-align: center;
	}

	.nav-menu.active {
		background: #efefef;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 11;
		width: 100%;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 65px;
		right: 20px;
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #132d40;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		background: #132d40;
		text-decoration: none;
		color: #fff;
		font-size: 1.5rem;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #132d40;
		transition: 250ms;
	}
}

.footer-background {
    background-color: #D9EDF4 !important;
}
.footer-content {
    padding-top: 40px !important; 
    padding-bottom: 40px !important; 
}
.footer-logo {
    width: 135px; 
}
.footer-contact {
    margin-top: 40px !important; 
    margin-bottom: 40px !important; 
}

@media screen and (max-width: 767px) {
    .footer-contact {
        margin-top: 15px !important; /* should be 30px but reduced because of default React Semantic UI padding */
    }
    .footer-sign-up-button {
        width: 100% !important;
    }
}
.h-120 {
	height: 120px;
}

@media screen and (max-width: 767px) {
	.h-120 {
		height: auto;
	}
}


.eventDetails {
    margin-top: 21px !important;
    padding: 20px !important;
}
.staff-section:hover {
	background-color: #ebebeb;
}

@media screen and (max-width: 752px) {
	.center {
		text-align: center !important;
	}
}

.round-image {
	border-radius: 50%;
}

.staff-icon-size {
	object-fit: cover;
	width: 250px;
	height: 250px; 
}
