@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap');

.neutral-0 {
	color: #fff;
}

.neutral-100 {
	color: #e0e1e3;
}

.neutral-200 {
	color: #c4c6ca;
}

.neutral-300 {
	color: #a8abb0;
}

.neutral-400 {
	color: #8d9198;
}

.neutral-500 {
	color: #73777f;
}

.neutral-600 {
	color: #5b5e65;
}

.neutral-700 {
	color: #45474c;
}

.neutral-800 {
	color: #2e3034;
}

.neutral-900 {
	color: #1a1b1d;
}

.neutral-1000 {
	color: #000;
}

.pxe-blue-100 {
	color: #d1d7dd;
}

.pxe-blue-200 {
	color: #b6c1c9;
}

.pxe-blue-300 {
	color: #9fadb8;
}

.pxe-blue-400 {
	color: #8899a7;
}

.pxe-blue-500 {
	color: #718496;
}

.pxe-blue-600 {
	color: #5a7286;
}

.pxe-blue-700 {
	color: #445f75;
}

.pxe-blue-800 {
	color: #294761;
}

.pxe-blue-900 {
	color: #223a50;
}

.red-100 {
	color: #d1d7dd;
}

.red-200 {
	color: #b6c1c9;
}

.red-300 {
	color: #9fadb8;
}

.red-400 {
	color: #8899a7;
}

.red-500 {
	color: #718496;
}

.red-600 {
	color: #5a7286;
}

.red-700 {
	color: #445f75;
}

.red-800 {
	color: #294761;
}

.red-900 {
	color: #223a50;
}

.blue-100 {
	color: #d1d7dd;
}

.blue-200 {
	color: #b6c1c9;
}

.blue-300 {
	color: #9fadb8;
}

.blue-400 {
	color: #8899a7;
}

.blue-500 {
	color: #718496;
}

.blue-600 {
	color: #5a7286;
}

.blue-700 {
	color: #445f75;
}

.blue-800 {
	color: #294761;
}

.blue-900 {
	color: #223a50;
}

.green-100 {
	color: #d1d7dd;
}

.green-200 {
	color: #b6c1c9;
}

.green-300 {
	color: #9fadb8;
}

.green-400 {
	color: #8899a7;
}

.green-500 {
	color: #718496;
}

.green-600 {
	color: #5a7286;
}

.green-700 {
	color: #445f75;
}

.green-800 {
	color: #294761;
}

.green-900 {
	color: #223a50;
}

.bg-default {
	background-color: #fff;
}

.bg-subtle {
	background-color: #a8abb0;
}

.bg-brand {
	background-color: #d9edf4;
}

.bg-brand:hover {
	background-color: #bdd4e0;
}

.bg-knockout {
	background-color: #052c53;
}

.content-default {
	color: #1b1c1e;
}

.content-subtle {
	color: #616368;
}

.content-default:hover {
	color: #052c53;
}

.content-brand {
	color: #052c53;
}

.content-brand:hover {
	color: #4d708f;
}

.content-knockout {
	color: #a8abb0;
}

.content-knockout:hover {
	color: #d9edf4;
}

.content-disabled {
	color: #a8abb0;
}

.border-default {
	border-color: #052c53;
}

.border-subtle {
	border-color: #d0d1d4;
}

.border-xsubtle {
	border-color: #eeeff0;
}

.border-brand {
	border-color: #6788a2;
}

.border-knockout {
	border-color: #ffffff;
}

.border-knockout-subtle {
	border-color: #6b7b92;
}

.border-knockout-xsubtle {
	border-color: #33597b;
}

.border-disabled {
	border-color: #d0d1d4;
}

.body-large {
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	font-family: Manrope;
	color: #000;
}

.body-default {
	font-family: Manrope;
	color: #000;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.body-small {
	font-family: Manrope;
	color: #1B1C1E;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.body-small-link {
	font-family: Manrope;
	color: #1B1C1E;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-decoration-line: underline;
}

.body-small-link:hover {
	text-decoration-line: underline;
}

.body-xsmall {
	font-family: Manrope;
	color: #000;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.body-xsmall-link {
	font-family: Manrope;
	color: #000;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-decoration-line: underline;
}

.body-xsmall-link:hover {
	text-decoration-line: underline;
}

.label-default {
	font-family: Manrope;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	line-height: 28px;
}

.label-small {
	font-family: Manrope;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

.display-large {
	font-family: Manrope;
	color: #000;
	font-size: 48px;
	font-weight: 300;
	line-height: 56px;
	letter-spacing: -2px;
}

.headline-large {
	font-family: Manrope;
	font-size: 48px;
	font-weight: 700;
	line-height: 56px;
	letter-spacing: -2px;
	color: #000;
}

.title-default {
	font-family: Manrope;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: -1px;
	color: #000;
}

.button-default {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: #000;
}
